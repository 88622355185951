<template>
    <div slot="tabs">
        <a
            v-for="tab in tabs"
            :key="`grid-tab-${keyHelper}-${tab.id}`"
            :href="tabLink(tab.id)"
            :class="{active: tab.active}"
            @click.prevent="changeTab(tab.id)"
        >
            <i
                v-if="tab.icon"
                class="icon"
                :class="[tab.icon]"
            />
            {{ tab.title }}
        </a>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    export default {

        name: 'GridHeaderTabs',

        props: {
            tabs: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                keyHelper: generateId(),
            };
        },

        methods: {

            /**
             * Tab link
             *
             * @param tab
             */
            tabLink(tab) {
                const route = this.$router.resolve({
                    name  : this.$route.name,
                    params: this.cloneDeep(this.$route.params || {}),
                    query : { ...this.$route.query, tab },
                });
                return route.href;
            },

            /**
             * Change tab
             *
             * @param id
             */
            changeTab(id) {
                const payload = {
                    refresh: 'grid',
                    tab    : id,
                };
                this.$emit('refresh', payload);
            },

        },

    };
</script>
